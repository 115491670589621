import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMediaTop: {
    width: "100%",
    height: "160px"
  },
  cardMedia: {
    width: 210,
  },
});

export default function Post({ post, excerpt, readTime }) {
  const classes = useStyles();

  return (
    <Box pb={4}>
      <CardActionArea component="a" href={post.path}>
        <Card className={classes.card}>
          <div className={classes.cardDetails}>
            <Hidden smUp>
              <CardMedia className={classes.cardMediaTop} image={post.backgroundImage} title={post.imageTitle} style={{backgroundColor: "#fff"}} />
            </Hidden>
            <CardContent>
              <Typography component="h2" variant="h5">
                {post.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {post.date} / {readTime}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {excerpt}
              </Typography>
            </CardContent>
          </div>
          <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.backgroundImage} title={post.imageTitle} style={{backgroundColor: "#fff"}} />
          </Hidden>
        </Card>
      </CardActionArea>
      </Box>   
  );
}