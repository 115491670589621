import { Box, Container, Typography } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Logo from "assets/images/budgetocity-circle-tm.png";
import BaseLayout from "components/BaseLayout";
import Footer from "components/Footer";
import Header from "components/Header";
import Post from "components/Post";

export default () => {
  const result = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { order: DESC, fields: frontmatter___date }
          filter: { frontmatter: { blog: { eq: true } } }
        ) {
          nodes {
            excerpt
            frontmatter {
              author
              backgroundImage
              title
              twitter
              path
              date(formatString: "MMMM DD, YYYY")
              blog
            }
            fields {
              slug
              readingTime {
                text
              }
            }
          }
        }
      }
    `
  );

  return (
    <BaseLayout 
      title={"The Budgetocity Blog"}
      description={"Blogging with a Twist"}
      indexable={true}>
      <Header />

      <Container maxWidth="xl">
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src={Logo} alt="Budgetocity logo" width="240px"/>
          <Box pt={2}>
            <Typography variant="h2">The Budgetocity Blog</Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="h3" gutterBottom>
              Blogging with a Twist
            </Typography>
          </Box>
          <Box pb={2}>
            {result.allMarkdownRemark.nodes.map(
              ({ frontmatter, excerpt, fields }, i) => (
                <Post post={frontmatter} excerpt={excerpt} readTime={fields.readingTime.text} key={`post${i}`} />
              )
            )}
          </Box>
        </Box>
      </Container>
      <Footer showImageDisclaimer/>
    </BaseLayout>
  );
};
